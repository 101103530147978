import { pickValue, requireValue } from '../../../utils/trip';
import { isMoment } from '../../../utils/formatDate';

import { ERRORSFORALL, ERRORSFORCUSTOM, ERRORS_FOR_BUS } from '../../../../bi/constants/tripValidator';

const requiredField = value => Boolean(value.length);

const requiredCommission = value => Boolean(String(value));

const requiredPrice = value => value && value > 0;

const employees = employee => !employee ? Boolean(employee) : Boolean(employee.Id);

const requiredProvider = value => value && Object.keys(value).length > 0;

const requiredDate = value => isMoment(value) && value.isValid();

const requireFile = value => value !== null;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORCUSTOM.NO_EMPLOYEE,
  },
  PassportId: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_PASSPORT,
  },
  OrderId: {
    fn: requireValue,
    msg: ERRORS_FOR_BUS.NO_ORDER_ID,
  },
  Providers: {
    fn: requiredProvider,
    msg: ERRORS_FOR_BUS.NO_PROVIDERS,
  },
  ProviderName: {
    fn: requiredProvider,
    msg: ERRORSFORCUSTOM.NO_PROVIDER,
  },
  TicketNumber: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_TICKET_NUMBER,
  },
  SeatNumber: {
    fn: requireValue,
    msg: ERRORS_FOR_BUS.NO_SEAT_NUMBER,
  },
  Luggage: {
    fn: requireValue,
    msg: ERRORS_FOR_BUS.NO_LUGGAGE_TYPE,
  },
  Departure: {
    fn: requireValue,
    msg: ERRORS_FOR_BUS.NO_DEPARTURE,
  },
  Destination: {
    fn: requireValue,
    msg: ERRORS_FOR_BUS.NO_DESTINATION,
  },
  CheckinDate: {
    fn: requiredDate,
    msg: ERRORS_FOR_BUS.NO_DEPARTURE_DATE_TIME,
  },
  CheckoutDate: {
    fn: requiredDate,
    msg: ERRORS_FOR_BUS.NO_DESTINATION_DATE_TIME,
  },
  Price: {
    fn: requiredPrice,
    msg: ERRORS_FOR_BUS.NO_BASE,
  },
  commission: {
    fn: requiredCommission,
    msg: ERRORSFORALL.NO_COMMISSION,
  },
  HasVAT: {
    fn: requiredField,
    msg: ERRORS_FOR_BUS.NO_VAT,
  },
  ValidFile: {
    fn: requireFile,
    msg: ERRORS_FOR_BUS.NO_PICK_FILE,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
};

class BusValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default BusValidator;

import moment from 'moment';

import { ERRORSFORALL, ERRORS_FOR_EVENT } from '../../../../bi/constants/tripValidator';
import { FIELDS, INN_LENGHTS } from '../../../../bi/constants/trips';

import {
  isMoment,
  getMoment,
  formatDataValue,
  compareDateWithToday,
  isBeforeDateParameter,
  isSameDateBySecond,
} from '../../../../bi/utils/formatDate';
import { pickValue, requiredTime } from '../../../utils/trip';

const DATE_TIME_FIELDS = [
  FIELDS.CHECKIN_DATE,
  FIELDS.CHECKOUT_DATE,
  FIELDS.CHECKIN_DATE_EDIT,
  FIELDS.CHECKOUT_DATE_EDIT,
];

const DEFAULT_TIME = '00:00';

const requiredField = value => Boolean(value.length);

const requiredPrice = value => value >= 0;

const requiredDate = value => moment.isMoment(value) && value.isValid();

const checkinDateIsLessThanCheckoutDate = (value) => {
  const { CheckoutDate, CheckinDate } = value;

  const checkinDate = `${formatDataValue(CheckinDate)} ${DEFAULT_TIME}`;
  const checkoutDate = `${formatDataValue(CheckoutDate)} ${DEFAULT_TIME}`;
  const compareDatesWithToday = compareDateWithToday(checkinDate) >= compareDateWithToday(checkoutDate) || isSameDateBySecond(checkinDate, checkoutDate);

  return compareDatesWithToday;
};

const checkinDateLessThanToday = value => !isBeforeDateParameter(value.CheckinDate, getMoment());

const checkoutDateLessThanToday = value => !isBeforeDateParameter(value.CheckoutDate, getMoment());

const requiredProviderName = value => value && value.split(' ').join('').length > 0;

const requiredMinLengthINN = value => value && value !== '-' ?
  Object.keys(INN_LENGHTS).find(key => INN_LENGHTS[key] === value.length) :
  true;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Name: {
    fn: requiredField,
    msg: ERRORS_FOR_EVENT.NO_NAME,
  },
  CheckinDate: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORS_FOR_EVENT.NO_CHECKIN_DATE,
    msg2: ERRORS_FOR_EVENT.DATE_LESS_TODAY,
  },
  CheckinTime: {
    fn: requiredTime,
    msg: ERRORS_FOR_EVENT.NO_CHECKIN_TIME,
  },
  CheckoutDate: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORS_FOR_EVENT.NO_CHECKOUT_DATE,
  },
  CheckoutDateLess: {
    fn: checkinDateIsLessThanCheckoutDate,
    msg: ERRORS_FOR_EVENT.NO_CHECKIN_DATE_IS_LESS,
  },
  CheckoutTime: {
    fn: requiredTime,
    msg: ERRORS_FOR_EVENT.NO_CHECKOUT_TIME,
  },
  CheckinDateEdit: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORS_FOR_EVENT.NO_CHECKIN_DATE,
    msg2: ERRORS_FOR_EVENT.DATE_LESS_TODAY_EDIT,
  },
  CheckoutDateEdit: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORS_FOR_EVENT.NO_CHECKOUT_DATE,
    msg2: ERRORS_FOR_EVENT.DATE_LESS_TODAY_EDIT,
  },
  Base: {
    fn: requiredPrice,
    msg: ERRORS_FOR_EVENT.NO_BASE,
  },
  ProviderName: {
    fn: requiredProviderName,
    msg: ERRORS_FOR_EVENT.NO_PROVIDER,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  ProviderInn: {
    fn: requiredMinLengthINN,
    msg: ERRORSFORALL.MIN_LENGTH_VALUE,
  },
};

class EventValidator {
  isValid(field, value) {
    const part = field.split('.');
    let result = '';

    if (part.length === 1) {
      let validator;
      let validationFn;

      if (DATE_TIME_FIELDS.includes(field) && !isMoment(value) && value !== '') {
        validator = validationObj[field];
        validationFn = validator.fn2;

        if (!validationFn(value)) {
          result = validator.msg2;
        }
      } else {
        validator = validationObj[field];
        validationFn = validator.fn;

        if (!validationFn(value)) {
          result = validator.msg;
        }
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        result = validator.msg;
      }
    }

    return result;
  }
}

export default EventValidator;

import { ERRORS_FOR_TAXI } from '../../../../bi/constants/tripValidator';

const requireValue = value => !!value;

const validationObj = {
  OrderId: {
    fn: requireValue,
    msg: ERRORS_FOR_TAXI.NO_ORDER_ID,
  },
};

class TaxiValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default TaxiValidator;

import moment from 'moment';

import { ERRORSFORALL, ERRORSFORHOTEL } from '../../../../bi/constants/tripValidator';
import { FIELDS, INN_LENGHTS } from '../../../../bi/constants/trips';
import { timePattern } from '../../../../bi/constants/regExp';

import {
  getMoment,
  isBeforeDateParameter,
  isValidMomentObject,
} from '../../../../bi/utils/formatDate';
import { pickValue, requireValue } from '../../../utils/trip';
import { dateAfterOtherDate, fullDateAfterOtherDate } from '../../../utils/hotels';

const requiredField = value => Boolean(value.length);

const requiredValue = value => value;

const requiredDate = value => moment.isMoment(value) && value.isValid();

const employees = employee => Boolean(employee.Id);

const checkinDateLessThanToday = value => !isBeforeDateParameter(value.CheckinDate, getMoment());

const checkoutDateLessThanToday = value => !isBeforeDateParameter(value.CheckoutDate, getMoment());

const isIntegerNumber = value => Number.isInteger(Number(value));

const requiredProvider = value => value && value !== 'default';

const requiredMinLengthINN = value => value && value !== '-' ?
  Object.keys(INN_LENGHTS).find(key => INN_LENGHTS[key] === value.length)
  : true;

const requiredTime = value => Boolean(value.length) && timePattern.test(value);

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  ReservationNumber: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NORESERVNUMBER,
  },
  GuestCount: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOGUESTS,
  },
  Hotel: {
    CustomHotelName: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NONAME,
    },
    Name: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NONAME,
    },
    Address: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NOADRESS,
    },
    City: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NOCITY,
    },
    Location: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NO_LOCATION,
    },
    CheckoutTime: {
      fn: requiredTime,
      msg: ERRORSFORHOTEL.NO_CHECKOUT_TIME,
    },
    CheckoutTimeLess: {
      fn: fullDateAfterOtherDate,
      msg: ERRORSFORHOTEL.NO_CHECKIN_TIME_IS_LESS,
    },
    CheckinTime: {
      fn: requiredTime,
      msg: ERRORSFORHOTEL.NO_CHECKIN_TIME,
    },
  },
  Room: {
    Category: {
      fn: requiredField,
      msg: ERRORSFORHOTEL.NO_CATEGORY,
    },
  },
  ReservationDate: {
    fn: requiredDate,
    msg: ERRORSFORHOTEL.NORESERVDATE,
  },
  CheckinDate: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORSFORHOTEL.NOCHECKINDATE,
    msg2: ERRORSFORHOTEL.DATELESSTODAY,
  },
  CheckoutDate: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORHOTEL.NOCHECKOUTDATE,
    msg2: ERRORSFORHOTEL.DATE_LESS_CHECKOUT_DATE,
  },
  CheckoutDateLess: {
    fn: dateAfterOtherDate,
    msg: ERRORSFORHOTEL.NOCHECKINDATEISLESS,
  },
  CheckinDateEdit: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORSFORHOTEL.NOCHECKINDATE,
    msg2: ERRORSFORHOTEL.DATELESSTODAYEDIT,
  },
  CheckoutDateEdit: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORHOTEL.NOCHECKOUTDATE,
    msg2: ERRORSFORHOTEL.DATELESSTODAYEDIT,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  commission: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOCOMISSION,
  },
  Base: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOBASE,
  },
  Commission: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOCOMISSION,
  },
  Provider: {
    fn: requiredProvider,
    msg: ERRORSFORHOTEL.NOPROVIDER,
  },
  HasVat: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOHASVAT,
  },
  EarlyCheckIn: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOEARLYCHECKIN,
  },
  LateCheckOut: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOLATECHECKOUT,
  },
  Total: {
    fn: requiredValue,
    fn2: isIntegerNumber,
    msg: ERRORSFORHOTEL.SUM_CANCELLATION,
    msg2: ERRORSFORHOTEL.NO_INTEGER_NUMBER,
  },
  From: {
    fn: requiredDate,
    msg: ERRORSFORHOTEL.DATE_CANCELLATION,
  },
  Penalty: {
    Base: {
      fn: requiredValue,
      msg: ERRORSFORHOTEL.BASEPENALTY_CANCELLATION,
    },
  },
  BreakfastName: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NOBREAKFASTNAME,
  },
  ProviderInn: {
    fn: requiredMinLengthINN,
    msg: ERRORSFORALL.MIN_LENGTH_VALUE,
  },
  VatAmount: {
    fn: requireValue,
    msg: ERRORSFORHOTEL.VAT_AMOUNT,
  },
  CurrencyMFInCurrency: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NO_MARKETING_FEE,
  },
  CurrencyInclusive: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.NO_INCLUSIVE,
  },
  CurrencyBase: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.CALCULATE_IN_CURRENCY,
  },
  CurrencyCommission: {
    fn: requiredValue,
    msg: ERRORSFORHOTEL.CALCULATE_IN_CURRENCY,
  },
};

class HotelValidator {
  isValid(field, value) {
    const part = field.split('.');

    if (part.length === 1) {
      let validator;
      let validationFn;

      if ((field === FIELDS.CHECKOUT_DATE ||
        field === FIELDS.CHECKIN_DATE ||
        field === FIELDS.CHECKIN_DATE_EDIT ||
        field === FIELDS.CHECKOUT_DATE_EDIT ||
        field === FIELDS.TOTAL_PENALTY) &&
        isValidMomentObject(value) &&
        value !== '') {
        validator = validationObj[field];
        validationFn = validator.fn2;

        if (!validationFn(value)) {
          return validator.msg2;
        }
      } else {
        validator = validationObj[field];
        validationFn = validator.fn;

        if (!validationFn(value)) {
          return validator.msg;
        }
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        return validator.msg;
      }
    }

    return '';
  }
}

export default HotelValidator;

import moment from 'moment';

import { ERRORSFORALL, ERRORS_FOR_VIP_HALL } from '../../../../bi/constants/tripValidator';

import { pickValue } from '../../../utils/trip';

const requiredDate = value => moment.isMoment(value) && value.isValid();

const requiredPrice = value => value >= 0;

const requiredField = value => Boolean(value.length);

const employees = employee => Boolean(employee.Id);

const employeesList = list => list.length > 0;

const requiredProviderName = value => value && value.length > 0;

const requiredProvider = value => value && Object.keys(value).length > 0;

const requireNumber = value => typeof value === 'number';
const requireValue = value => !!value;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  EmployeesList: {
    fn: employeesList,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  CheckinDate: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CHECKINDATE,
  },
  CheckinTime: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CHECKINTIME,
  },
  CancellationDate: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CANCELLATIONDATE,
  },
  CancellationTime: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CANCELLATIONTIME,
  },
  CheckoutDate: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CHECKOUTDATE,
  },
  CheckoutTime: {
    fn: requiredDate,
    msg: ERRORS_FOR_VIP_HALL.NO_CHECKOUTTIME,
  },
  DepartureAirport: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_AIRPORT_FROM,
  },
  ArrivalAirport: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_AIRPORT_TO,
  },
  Base: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_PRICE,
  },
  Commission: {
    fn: requireNumber,
    msg: ERRORS_FOR_VIP_HALL.NO_COMMISSION,
  },
  Providers: {
    fn: requiredProvider,
    msg: ERRORS_FOR_VIP_HALL.NO_PROVIDER,
  },
  ProviderName: {
    fn: requiredProviderName,
    msg: ERRORS_FOR_VIP_HALL.NO_PROVIDER,
  },
  Name: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_NAME,
  },
  OrderId: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_ORDER_ID,
  },
  Terminal: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_TERMINAL,
  },
  commission: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NOCOMISSION,
  },
  ProviderOrderId: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_PROVIDER_ORDER_ID,
  },
  Number: {
    fn: requireValue,
    msg: ERRORS_FOR_VIP_HALL.NO_NUMBER,
  },
};

class VipHallValidator {
  isValid(field, value) {
    const part = field.split('.');

    if (part.length === 1) {
      const validator = validationObj[field];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        return validator.msg;
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        return validator.msg;
      }
    }

    return '';
  }
}

export default VipHallValidator;

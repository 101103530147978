import moment from 'moment';

import { ERRORSFORALL, ERRORSFORCUSTOM } from '../../../../bi/constants/tripValidator';
import { FIELDS, INN_LENGHTS } from '../../../../bi/constants/trips';
import { DEFAULTTIME } from '../../../../constants/time';

import {
  isMoment,
  getMoment,
  formatDataValue,
  isSameDateBySecond,
  isValidMomentObject,
  compareDateWithToday,
  isBeforeDateParameter,
} from '../../../../bi/utils/formatDate';
import { pickValue, requiredTime, requireValue } from '../../../utils/trip';

const DATE_TIME_FIELDS = [
  FIELDS.CHECKIN_DATE,
  FIELDS.CHECKOUT_DATE,
  FIELDS.CHECKIN_DATE_EDIT,
  FIELDS.CHECKOUT_DATE_EDIT,
];

const DEFAULT_TIME = '00:00';


const requiredField = value => Boolean(value.length);

const requiredPrice = value => value >= 0;

const requiredDate = value => moment.isMoment(value) && value.isValid();

const employees = employee => Boolean(employee.Id);

const checkinDateIsLessThanCheckoutDate = (value) => {
  const { CheckoutDate, CheckinDate, CheckinTime, CheckoutTime } = value;

  if (isMoment(CheckinTime) && isMoment(CheckoutTime)) {
    const checkinDate = `${formatDataValue(CheckinDate)} ${isValidMomentObject(CheckinTime) ? formatDataValue(CheckinTime, DEFAULTTIME) : DEFAULT_TIME}`;
    const checkoutDate = `${formatDataValue(CheckoutDate)} ${isValidMomentObject(CheckoutTime) ? formatDataValue(CheckoutTime, DEFAULTTIME) : DEFAULT_TIME}`;
    const compareDatesWithToday = compareDateWithToday(checkinDate) >= compareDateWithToday(checkoutDate) || isSameDateBySecond(checkinDate, checkoutDate);

    return compareDatesWithToday;
  }

  return false;
};

const checkinDateLessThanToday = value => !isBeforeDateParameter(value.CheckinDate, getMoment());

const checkoutDateLessThanToday = value => !isBeforeDateParameter(value.CheckoutDate, getMoment());

const requiredProviderName = value => value && value.length > 0;

const requiredProvider = value => value && Object.keys(value).length > 0;

const requiredMinLengthINN = value => value && value !== '-' ?
  Object.keys(INN_LENGHTS).find(key => INN_LENGHTS[key] === value.length) :
  true;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Name: {
    fn: requiredField,
    msg: ERRORSFORCUSTOM.NO_NAME,
  },
  CheckinDate: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORSFORCUSTOM.NO_CHECKIN_DATE,
    msg2: ERRORSFORCUSTOM.DATE_LESS_TODAY,
  },
  CheckinTime: {
    fn: requiredTime,
    msg: ERRORSFORCUSTOM.NO_CHECKIN_TIME,
  },
  CheckoutDate: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORCUSTOM.NO_CHECKOUT_DATE,
    msg2: ERRORSFORCUSTOM.DATE_LESS_TODAY,
  },
  CheckoutDateLess: {
    fn: checkinDateIsLessThanCheckoutDate,
    msg: ERRORSFORCUSTOM.NO_CHECKIN_DATE_IS_LESS,
  },
  CheckoutTime: {
    fn: requiredTime,
    msg: ERRORSFORCUSTOM.NO_CHECKOUT_TIME,
  },
  CheckinDateEdit: {
    fn: requiredDate,
    fn2: checkinDateLessThanToday,
    msg: ERRORSFORCUSTOM.NO_CHECKIN_DATE,
    msg2: ERRORSFORCUSTOM.DATE_LESS_TODAY_EDIT,
  },
  CheckoutDateEdit: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORCUSTOM.NO_CHECKOUT_DATE,
    msg2: ERRORSFORCUSTOM.DATE_LESS_TODAY_EDIT,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORCUSTOM.NO_EMPLOYEE,
  },
  Base: {
    fn: requiredPrice,
    msg: ERRORSFORCUSTOM.NO_BASE,
  },
  commission: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NOCOMISSION,
  },
  Commission: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NOCOMISSION,
  },
  Providers: {
    fn: requiredProvider,
    msg: ERRORSFORCUSTOM.NO_PROVIDER,
  },
  ProviderName: {
    fn: requiredProviderName,
    msg: ERRORSFORCUSTOM.NO_PROVIDER,
  },
  OrderId: {
    fn: requireValue,
    msg: ERRORSFORCUSTOM.NO_ORDER_ID,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  ProviderInn: {
    fn: requiredMinLengthINN,
    msg: ERRORSFORALL.MIN_LENGTH_VALUE,
  },
};

class CustomValidator {
  isValid(field, value) {
    const part = field.split('.');
    let result = '';

    if (part.length === 1) {
      let validator;
      let validationFn;

      if (DATE_TIME_FIELDS.includes(field) && isValidMomentObject(value) && value !== '') {
        validator = validationObj[field];
        validationFn = validator.fn2;

        if (!validationFn(value)) {
          result = validator.msg2;
        }
      } else {
        validator = validationObj[field];
        validationFn = validator.fn;

        if (!validationFn(value)) {
          result = validator.msg;
        }
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        result = validator.msg;
      }
    }

    return result;
  }
}

export default CustomValidator;

import ACTIONS from '../../actions';
import Api from '../../api';

import { INVALID_LINK } from '../../constants/unloading1c';

let store = null;

export default class Unloading1cService {
  constructor() {
    store = this.getStore('unloading1c');
  }

  get = () => store.getState();

  getPackageDocuments = paramsPackageDocuments => Api.Unloading1c.getPackageDocuments(paramsPackageDocuments)

  getInfoAmountsSupplier = paramsPackageDocuments => Api.Unloading1c.getInfoAmountsSupplier(paramsPackageDocuments)
  .then(res => store.dispatch({
    type: ACTIONS.UNLOADING1C.INFO_AMOUNTS_SUPPLIER,
    listCompany: res,
  }));

  getFileXml = guid => Api.Unloading1c.getFileXml(guid);

  resetStore = () => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.RESET,
    });
  };

  setReportType = (reportType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_REPORT_TYPE,
      payload: reportType,
    });
  };

  setError = (error) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_ERROR,
      payload: error,
    });
  };

  sendSettings = async (settings) => {
    try {
      await Api.Unloading1c.sendSettings(settings);
    } catch (error) {
      const { Error: { Comment: message } } = error.body;

      this.setError(message);
    }
  };

  getWorkSheets = async (link) => {
    try {
      const { Data } = await Api.Unloading1c.getWorkSheets(link);
      store.dispatch({
        type: ACTIONS.UNLOADING1C.SET_WORKSHEETS,
        payload: Data,
      });
    } catch (error) {
      if (!error.body) {
        return this.setError(INVALID_LINK);
      }

      const { Error: { Comment: message } } = error.body;

      this.setError(message);
    }
  };

  setStartDate = (startDate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_START_DATE,
      payload: startDate,
    });
  };

  setEndDate = (endDate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_END_DATE,
      payload: endDate,
    });
  };

  setCompany = (company) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COMPANY,
      payload: company,
    });
  };

  setCounteragent = (counteragent) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COUNTERAGENT,
      payload: counteragent,
    });
  };

  setTypeImplementation = (typeImplementation) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_TYPE_IMPLEMENTATION,
      payload: typeImplementation,
    });
  };

  setDisabledMonth = (disabledMonth) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_DISABLED_MONTH,
      payload: disabledMonth,
    });
  };

  setStep = (step) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_STEP,
      payload: step,
    });
  };

  setGroupingType = (groupingType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_GROUPING_TYPE,
      payload: groupingType,
    });
  };

  setDayType = (dayType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_DAY_TYPE,
      payload: dayType,
    });
  };

  setAmountType = (amountType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_AMOUNT_TYPE,
      payload: amountType,
    });
  };

  setNomenclature = (nomenclatureVatRate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_NOMENCLATURE,
      payload: nomenclatureVatRate,
    });
  };

  setComment = (comment) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COMMENT,
      payload: comment,
    });
  };

  setProvider = (provider) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PROVIDER,
      payload: provider,
    });
  };

  getPrincipals = async (provider) => {
    try {
      const data = await Api.Unloading1c.getPrincipals(provider);
      this.setPrincipals(data);
    } catch (error) {
      this.setError(error.statusText);
    }
  }

  setPrincipals = (principals) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PRINCIPALS,
      payload: principals,
    });
  };

  setPrincipal = (principal) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PRINCIPAL,
      payload: principal,
    });
  };

  setFileLink = (fileLink) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_FILELINK,
      payload: fileLink,
    });
  };

  setSheet = (sheet) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_SHEET,
      payload: sheet,
    });
  };

  setTripItemColumnName = (tripItemColumnName) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_TRIP_ITEM_COLUMN_NAME,
      payload: tripItemColumnName,
    });
  };
}

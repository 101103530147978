const mapUserUrlFilterToUserStatus = (filter) => {
  switch (filter) {
    case 'awaiting':
      return 'Pending';
    case 'confirmed':
      return 'Approved';
    case 'rejected':
      return 'Rejected';
    case 'new':
    default: return 'Created';
  }
};

export default mapUserUrlFilterToUserStatus;

const requiredField = value => Boolean(value.length);

const validationObj = {
  name: {
    fn: requiredField,
    msg: 'Введите название поездки',
  },
};

class TripValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    const validationFn = validator.fn;
    let result = '';

    if (!validationFn(value)) {
      result = validator.msg;
    }

    return result;
  }
}

export default TripValidator;
